import React, { Component } from "react";
import Layout from "../components/layout"
import { graphql } from "gatsby";
import SEO from "../components/seo"

class TagTemplate extends Component {
  render() {
    const data = this.props.data.contentfulTag;
    const posts = this.props.data.allContentfulPost.edges;
    let allPostsWithTag = [];

    posts.forEach(post => {
      const tags = post.node.tags;
      for (var i=0; i<tags.length; i++) {
        if (tags[i].name === data.name) {
          allPostsWithTag.push(post);
        }
      }
      return;
    })

    return (
      <Layout>
        <SEO title={`futurefeed | ${data.name}`} />
        <div className="all-page">
        <h2>{data.name}</h2>
        {allPostsWithTag.map((post, i) => {
          if (post.node.slug !== "testing-post" && post.node.slug !== "testing-post-2") {
            return (
              <a href={`/${post.node.slug}`} key={i}><h3>{post.node.title}</h3></a>
            )
          }
        })}
        </div>
      </Layout>
    )
  }
}

export default TagTemplate

export const pageQuery = graphql`
  query tagQuery($slug: String!) {
    contentfulTag(slug: { eq: $slug }) {
      name
    }

    allContentfulPost {
      edges {
        node {
          id
          slug
          title
          tags {
            ... on ContentfulTag {
              name
            }
          }
        }
      }
    }
  }
`
